body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Typist .Cursor {
   display: inline-block;
}
.Typist .Cursor--blinking {
   opacity: 1;
   animation: blink 1s linear infinite;
}

@keyframes blink {
   0% {
      opacity: 1;
   }
   50% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
